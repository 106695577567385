import { CommonSecurity, CommonState, Fetcher, FetchHelper } from '@primeit/components-vue';
import { DavWebSocket } from '@/components/dav.websocket';

const fetchHelper = new FetchHelper();
let ws, monitor;

const module = {
  actions: {
    EMERGENCY_STATUS: async (context, params) => {
      let json;
      try {
        const response = await Fetcher(`${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_EMERGENCY}`, {
          method: 'GET',
        });
        if (response.ok) {
          json = await response.json();
        }
      } catch (e) {
        json = {
          code: 400,
          message: 'Impossível acessar o servidor. Tente novamente',
        };
      }
      context.commit('POPULATE_EMERGENCY_STATUS', json);
    },
    FETCH_GET_GROUP: async (context, params) => {
      try {
        const response = await fetchHelper.fetchAll(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_EMERGENCY}/group`,
          params,
          true
        );
        if (response) {
          context.commit('POPULATE_GROUP', response);
        }
      } catch (e) {
        context.commit('common/POPULATE_FEEDBACK', e, {
          root: true,
        });
      }
    },
    FETCH_PRINT_TAG: async (context, params) => {
      try {
        const response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_EMERGENCY}/generate-tag`,
          {
            body: JSON.stringify(params),
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        );

        if (response.ok) {
          const pdfBuffer = await response.arrayBuffer();

          try {
            const integrationURL = `https://localhost:5117/IntegrationDrAoVivo/print`;
            await Fetcher(integrationURL, {
              body: pdfBuffer,
              headers: {
                'Content-Type': 'application/pdf'
              },
              method: 'POST',
            });
          } catch (err) {
            console.error(err);
          }
        }
      } catch (error) {
        context.commit(
          'common/POPULATE_FEEDBACK',
          {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          },
          {
            root: true,
          }
        );
      }
    },
    FETCH_SAVE_NPS: async (context, params) => {
      let response;
      let json;

      const protocol = params.protocol;

      try {
        response = await Fetcher(
          `${process.env.VUE_APP_SERVICES_ENDPOINT_BASE_APPOINTMENT}/npsreport/?protocol=${protocol}`,
          {
            body: JSON.stringify(params.body),
            headers: {
              Accept: 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
            },
            method: 'POST',
          }
        );
        json = await response.json();
      } catch (error) {
        context.commit(
          'common/POPULATE_FEEDBACK',
          {
            code: 400,
            message: 'Impossível acessar o servidor. Tente novamente',
          },
          {
            root: true,
          }
        );
      }
      context.commit('common/POPULATE_FEEDBACK', json, {
        root: true,
      });
    },
    // NPS_REPONDED: async (context, params) => {

    // },
    send(context, params) {
      ws.send(JSON.stringify(params));
    },
    start: async (context, params) => {
      const cognitoUser = await CommonSecurity.getUser();
      if (monitor) clearInterval(monitor);

      context.commit('monitor', WebSocket.CLOSING);

      ws = new DavWebSocket(
        process.env.VUE_APP_SERVICES_ENDPOINT_BASE_FLOW,
        cognitoUser.token.id,
        params.resume,
        params.heartbeat
      );

      ws.onopen = (event) => {
        // console.debug('OPEN', event);
        monitor = setInterval(() => {
          ws.monitor().then((monitor) => context.commit('monitor', monitor));
        }, 1000);
      };

      ws.onmessage = (event) => {
        const dto = JSON.parse(event.data);
        if (dto) {
          if ('status' in dto) {
            context.commit('status', dto);
          } else {
            context.commit('change', dto);
          }
        }
      };

      ws.onerror = (event) => {
        // console.error('ERRO', event);
        if (monitor) clearInterval(monitor);
        context.commit('monitor', WebSocket.CLOSED);
      };

      ws.onclose = (event) => {
        // console.debug('CLOSE', event);
        if (monitor) clearInterval(monitor);
        context.commit('monitor', WebSocket.CLOSED);
      };

      ws.start();
    },
    stop(context) {
      // console.debug('vuex', 'emergency/stop');
      if (ws) {
        ws.close();
        context.commit('change', null);
        context.commit('status', null);
      }
    },
  },
  getters: {
    actual: (state) => state.actual,
    GET_EMERGENCY_STATUS: (state) => {
      return JSON.parse(JSON.stringify(state.emergencyStatus));
    },
    GET_GROUP: (state) => {
      return JSON.parse(JSON.stringify(state.groups));
    },
    monitor: (state) => state.monitor,
    status: (state) => state.status,
  },
  mutations: {
    change(state, payload) {
      state.actual = payload;
    },
    monitor(state, payload) {
      state.monitor = payload;
    },
    POPULATE_EMERGENCY_STATUS: (state, payload) => {
      if (payload) {
        state.emergencyStatus = payload;
      }
    },
    POPULATE_GROUP: (state, payload) => {
      if (payload) {
        state.groups = payload;
      }
    },
    status(state, dto) {
      if (dto) state.status = dto;
    },
  },
  namespaced: true,
  state: {
    actual: undefined,
    emergencyStatus: null,
    groups: null,
    monitor: undefined,
    status: undefined,
  },
};

CommonState.registerModule('emergency', module);
